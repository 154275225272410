function toggleMenu() {
  $(".hamburger-shell").toggleClass("menu-open") // Change hamburger icon style
  $("#mobile-menu").toggleClass("reveal") // Open hidden menu
  $("body").toggleClass("fixed") // Fix body so that it does not scroll while in menu
}

$(function () {
  $(".nav-toggle-menu").on("click", toggleMenu)
  $(".hamburger-shell").on("click", toggleMenu)
})
